@tailwind base;
@tailwind components;
@tailwind utilities;
.btn-sm-block{width: auto;}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
input { outline: none;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    line-height: 1;
    font-family: 'Poppins', sans-serif;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a { text-decoration: none !important; }

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
    right: 0;
}

/* .dropdown.menu:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
} */
.dropdown.profile .dropdown-menu {
  margin-top: 5px !important;
  transform: translate3d(0px, 0px, 0px) !important;
}


.top-menu{ background: #0C1C38; padding: 11px 0; }
.search { text-align: center; padding-top: 7px; margin: 0 auto;}
.search input { border: 0; border-radius: 3px;font-size: 18px; text-indent: 10px;  }
.search button { background: #F58B29; border-color: #F58B29}
.search .input-group { margin: 0 auto}
.search .input-group {width: 75%; margin: 0 auto;}
.search button i { color: #fff;}

.cart { display: flex; justify-content: space-evenly; align-items: center; padding-top: 7px; position: relative;}
.dropdown-toggle::after { 
    content: none; 
}
.cart a { position: relative;}

.ak-menu { background:#1A2F53; height: 45px;}
.ak-menu .nav-link {padding: 0.5rem 1rem !important; color: #fff;}
.ak-menu .nav-link:hover {background: #edb27d; border-radius: 1px !important;}
.ak-menu .nav-link.active { background: #F58B29; border-radius: 1px !important; }

.ak-menu .dropdown-menu, .cart .dropdown-menu { 
  background:#fff; 
  border-radius: 3px ;  

}
.ak-menu .dropdown-menu a { 
  display: block;
} 
.ak-menu .ul3 {
  display: none !important;
  position: absolute;
  left: 158px;
  top: -8px;
}
.ak-menu .ul3.ul40 {
  top: -40px;
}
.ak-menu .dropdown.menu:hover > .dropdown-menu {display: block !important;}
.ak-menu .dropdown.menu > .dropdown-menu:hover > .dropdown-menu{display: block !important;}

.ak-menu .dropdown.menu > .dropdown-menu:hover > .dropdown-menu.ul3 {display: block !important;}
/* .ak-menu ul ul a:hover  > .ul3 {display: block;} */

.cart .dropdown-menu { width: 120px; top: 34px !important}
.cart .dropdown-menu p { font-size: 12px; margin-top: 5px}
.cart .dropdown-menu p a {  
  text-decoration: underline; 
  color: #0C1C38;
}

.common-heading { margin-bottom: 20px;}
.common-heading h1 { font-size: 18px; color: #0C1C38; font-weight: 600; position: relative; display: inline-block;}
.common-heading h1::after { position: absolute; content:''; width: 73px; height: 5px; background: #F58B29; border-radius: 2px; right:    -100px; top: 7px;  }
.common-heading a { font-size: 14px; color: #0C1C38; float:inline-end; }


.brand { background:#EEEEEE; padding: 25px 0;}
.categories { padding: 50px 0;}
.categories .item {margin: 0 1rem !important;}
.categories a {display: block; }
.seller { background: #EEEEEE; padding: 50px 0}

.carousel .carousel_cnt,
.carousel .carousel_cnt > div{position: relative;}
.carousel .next, 
.carousel .prev {
  background: #f58b29;
  color: #fff;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  cursor: 'pointer';
  position: absolute;
}
.carousel .prev{left: 0; z-index: 1;}
.carousel .next{right: 0; z-index: 1;}

.brand-box { 
  width: 200px; 
  height: 200px;
  margin-right: 1rem !important; 
  background: #fff; 
  border-radius: 5px; 
  box-shadow: 0 0 6px #00000016; 
  padding: 20px; 
  text-align: center;
  position: relative;
}
.brand-box a  { display: inline-block; background: #808185; border-radius: 5px; padding: 8px 20px; color: #fff;  margin-top: 20px;}
.brand-box .item img {width: 100%; height: 100px;}

.product-box { text-align: center; width: 220px; position: relative;}
.product-box img { border-radius: 50%;     object-fit: cover; height: 191px;}
.product-box p { color: #0C1C38; font-size: 15px; font-weight: 600; padding-top: 20px; }

.seller-box {width: 300px;}
.seller-box .item-box { 
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px #00000016;
  padding: 10px;
  text-align: center;
  position: relative;
}
.seller-box .item-box img { border-radius: 5px 5px 0 0 }
.seller-box .item-box div:first-child {  padding: 0px;   border-bottom: 1px solid #70707030;}
.seller-box .item-box span.offer { top: 10px; right: 15px; position: absolute; font-size: 12px; background: #F58B29; padding: 3px; border-radius: 3px; color: #fff}
.seller-box .txt { padding: 15px; text-align: left;}
.seller-box .txt h3 { font-size: 14px; color: #0C1C38; margin-bottom: 5px; font-weight: 600;}
.seller-box .txt h2 { color: #0C1C38; font-size: 12px; background: #EEEEEE; padding: 2px 8px; border-radius: 2px; display: inline-block; margin: 6px 0; }
.seller-box .txt i { font-size: 10px; color: #F58B29;}
.seller-box .txt p { margin-top: 15px; font-weight: 600; margin-bottom: 0;}
.seller-box .txt p span { font-size: 14px;  margin-left: 20px; color: #707070; font-size: 13px} 
.seller-box .txt a { 
  background: #1A2F53; 
  display: block !important; 
  text-align: center !important;
  margin-top: 10px !important; 
  border-radius: 5px;
  padding: 8px 20px;
  color: #fff;
  text-decoration: none;
}

.product-detail { padding: 50px 0; background: #0C1C38;}
.best h3 { color:#fff;  text-transform: uppercase; font-size: 27px; font-weight: 600; margin-bottom: 20px;}
.best p { font-size: 17px; color: #fff; line-height: 20px }
.shop-now { text-align: right; padding-top: 40px}
.shop-now a, .ak-btn  { background: #F58B29; border-radius: 5px; padding: 8px 20px; color: #fff; text-decoration: none; }
.bulb { position: relative;}
.bulb img { position: absolute; top: -35px;}
.ak-btn  { border: none; display: block; margin: 0 auto;}

.resource{ padding: 25px 0;}
.achivement {background: #FBFBFB; padding: 25px 0}
.ra-common-box { display: flex; background: #F8F8F8; border: 1px solid #F2F2F2;}
.ra-text { padding: 10px;  position: relative;}
.ra-text h1 { color: #0C1C38; font-size: 14px; font-weight: 600; margin-bottom: 5px;}
.ra-text p { font-size: 14px; line-height: 20px;     overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;}
.ra-text a { color: #F58B29; font-size: 23px; position: absolute; right: 10px; bottom: 0px; } 
.resource .img-fluid {
  max-width: 100px;
  height: 100px;
}
.tracking-normal { min-height: 126px;}

.media  { padding: 25px 0;}
.media-box { padding: 20px 10px; background: url(../src/assets/news.jpg) no-repeat;  background-size: cover;}
.media-box h3 { font-size: 16px; font-weight: 500; color: #fff; margin-bottom: 20px;}
.media-box p { font-size: 14px; color: #fff}
.media-box p small { color: #F58B29; line-height: 30px}
.media-box span {  display: block; padding: 15px 0; color: #fff; font-size: 14px;}
.media-box i { color: #F58B29}

.btn-primary { background: #F58B29 !important; border-color: #F58B29 !important;}

.login-box {
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #e3e3e3;

}

footer { background: #0C1C38; }
footer a:hover{text-decoration: underline !important;}
.ft-menu { color: #fff; justify-content: space-evenly;padding: 20px 0;}
.ft-menu h3 { font-size: 18px; padding: 10px 0;}
.ft-menu p { font-size: 15px; line-height: 24px; font-weight: 300;}
.ft-menu p>a { font-size: 15px; color: #fff;}
.ft-menu a { font-size: 15px; color: #fff; display: block; line-height: 36px;}
.ft-menu-2 a { line-height: 26px !important;}


.app { position: relative; background:#DEE1E6; padding: 20px 20px; height: 100%;}
.app h3 { font-size: 18px; color: #0C1C38; font-weight: 500}
.app img { padding: 20px 0 0 20px;}

.copy p a {
  color: #fff;
  font-size: 14px;
}
.news button {
  background: #F58B29;
  border-color: #F58B29;
  color: #fff;
}

.w-8 {width: 2rem;}
.h-8 {height: 2rem;}

a.social_icon {
  background: #000;
  display: inline-block;
  padding: 0.1rem;
}

.cnt_about_us img.profile_img {
  width: 180px;
  max-width: 100%;
  height: auto;
  border-radius: 0.375rem;
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / .07)) drop-shadow(0 2px 2px rgb(0 0 0 / .06));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.cnt_media {}
.news_card{
  border-radius: 5px;
  background: #fffdfd;
  box-shadow: 2px 2px 2px rgba(0,0,0,.1);
  border: 1px solid #ccc;
}
.news_card img {
  height: 250px;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  border-radius: 5px 5px 0 0;
  object-fit: fill;
}
 .news_card h3 {
  overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
}
 .news_card {
  color: #000;
}
 .news_card .small {
  font-size: .75rem;
}
.cnt_contact img.contact_bg{
  width: auto;
  max-width: 100%;
}

.cnt_buyers .sec-offerings img{
  width: auto;
  max-width: 100%;
}
.cnt_buyers .key-projects ul {
  display: flex;
}
.cnt_buyers .key-projects ul li{
  list-style-type: none;
  display: inline-block;
  padding: 0.5rem;
  border: 1px solid #ece7e7;
  margin-right: 1rem;
}
.cnt_buyers .key-projects ul li img{
  height: 100px;
  width: auto;
}


.w-20 {
  width: 5rem;;
}


.cnt_subcat {}
.cnt_subcat ul{
  display: block;
}
.cnt_subcat ul li{
  list-style-type: none;
  display: inline-block;
}
.cnt_subcat ul li a{
  display: block;
  margin-right: 1rem;
  background-color: #ccc;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.cnt_subcat ul li a:hover{
  background-color: #000;
  color: #fff !important;
}

.dropdown-toggle::after {
  color: #fff;
}
.carousel .slide img {
  max-height: 400px !important;
  object-fit: cover;
}

.cnt_products {
  min-height: 400px;
}
.cnt_products .card-title {

  overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
}

.utablinks {background: #e3e3e3;
  display: flex; justify-content: space-between;}
.utablinks a {
  color: #000;
  
  padding: 11px 20px;
}



.utablinks a.active {
  background: #F58B29;
    color: #fff;
    font-weight: 600;
}

/* .container, .container-fluid {
  min-width: 1024px !important;
} */

.side-nav { display: none !important; position: relative;}
.menuicon { 
  background: none;
  border: none;}
.menuicon img { height: 35px;}
  .sidemenu  { padding-left: 5px; padding-top: 0; }
  .offcanvas-body { padding-top: 0 !important;}
  .sidemenu li { font-size: 16px;
    line-height: 33px; }
    .sidemenu li a {
      font-weight: 500 !important; }
  .offcanvas-title { border-bottom: 1px solid #e3e3e3; padding-bottom: 10px;}
  .offcanvas-header { padding-bottom: 0 !important;}
.app-img {display: flex; align-items: center;
}

.w-full{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.catbox {
  margin-bottom: 2rem;
}
.catbox img {
  height: 200px;
  object-fit: cover;
}
.min-height{
  min-height: 500px;;
}

.tell-us-your-req{
  background: #e3e3e3;
  padding: 10px;
}
.reqs .inner_cnt {
  padding: 0.5rem 0rem;
  margin: 0.5rem 0rem;
}
.reqs .inner_cnt select {
  border: 1px solid #c5c5c5;  font-size: 14px; color: #a3a3a3;
}

.reqs .common-heading { margin-bottom: 0; border-bottom: 1px solid #c5c5c5;}

.tell-us-your-req .btn-primary { margin-top: 20px;}
.menuicon { display: none;}
.reqs h6 { font-size: 15px }

.categories .item { margin:0 1rem  !important;}
.product-box img { padding: 10px ;}

.rdmrnu { left: 158px;
  top: 0;
  width: 400px;
  height: 200px;}

  .name-details { padding: 0 15px; }
  .name-details h2 { font-size: 18px; margin-bottom: 5px; }
  .listicon { list-style-type: disc;}
  .bread { background:#e3e3e3 ; padding: 10px;}

  .cartvalue { padding: 10px;
    font-size: 18px;
    font-weight: 600;}

  .cart-btn {    font-size: 27px !important;
    padding: 1px 5px !important;
    line-height: 30px !important;
    border: 2px solid !important; width: 32px !important;}  
    .cart-btn span { position: relative; top: -3px;}


@media screen and (max-width: 1024px) {
  .app img { padding-left: 0 !important; width: 90%;}
  .btn-sm-block{width: 100%;}
}
@media screen and (max-width: 896px) {
  .app-img {display:block; margin: 0 auto;}
  .reqs .common-heading h1::after { display: none !important;}
}

@media screen and (max-width: 768px) {
.telld { display: none;}
.utablinks {
  flex-direction: column;
}

.search { padding: 15px 0;}
.logo { text-align: center;}
.search input { width: 100%;  }
.ak-menu i { color: #fff}
.brand-box { margin-bottom: 15px; justify-content: space-evenly; display: flex; align-items: center;}
.brand-box a { margin-top: 0;}
.product-box { margin-bottom: 25px; padding: 30px;}
.saller .brand-box { display: block}
.bulb { text-align: center; padding-top: 20px;}
.bulb img { position: relative;}
.product-box img {box-shadow: none;}
.app { height:auto; text-align: center;}
.shop-now { text-align: center; padding-top: 0; }
.ra-common-box, .media-box { margin-bottom: 20px}
.app img { top: 0; right: 0; width: auto;}
.navbar-collapse{ position: relative; z-index: 9;}
.side-nav { display: block !important;}
.offcanvas { width: 250px !important; }
.ak-menu { display: none !important;}

.ft-menu { padding: 10px 0;}
.ft-menu h3 {padding: 10px 0;}
.ft-menu a {line-height: 26px;}
.ft-menu div { width: 200px;}
.resource { padding-bottom: 0px;}
.resource2 { padding-top: 0 !important;}
.product-detail { margin-bottom: 25px;}
.tell-us-your-req .form-control { margin-bottom: 20px;}
.tell-us-your-req .btn-primary { margin-top: 0px;}
.menuicon { display: block;}
}
 
  @media screen and (min-width: 410px) {
    .categories .item { margin:0  !important;}
    .product-box {padding: 0 !important; }
    .product-box img { height: 220px;}
  }

img.mh{
  max-height: 220px;
  height: 220px;;
}

.uldisc {
  list-style-type: disc;;
  padding-left: 1rem;
}

.img.abt {
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}

.input_mw_100{
  max-width: 100px;
}

.img_mobile {
  height: 240px !important;
}
