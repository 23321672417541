.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-dot {
    background-color: #333;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 2px;
  
    animation-name: loading;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  
  .loading-dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loading-dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loading {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }